import React from 'react';
import { Typography, Button } from '@mui/material';

interface CardProps {
  title?: string;
  price?: string; // Precio con descuento
  originalPrice?: string; // Precio sin descuento
  year?: string;
  description?: string;
  performanceDrawing?: string,
  button?: string;
  topChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  downloadUrl?: string; // Agregado para la URL de descarga
}

const Card: React.FC<CardProps> = ({
  title = 'Hola',
  price = '3456', // Precio con descuento
  originalPrice = '4000', // Precio sin descuento
  year = '1 año',
  description = 'description de ejemplo',
  button = 'comprar',
  topChildren,
  performanceDrawing,
  bottomChildren,
  downloadUrl,
}) => {

  return (
    <article className="card" style={{ position: 'relative' }}>
      
      {/* <div style={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        backgroundColor: '#00E2B1', // Color de fondo similar al de la imagen
        color: 'black',
        padding: '5px 10px',
        borderRadius: '0 20px 0 10px',
        fontWeight: 'bold',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center'
      }}>
        <span role="img" aria-label="discount" style={{ marginRight: '5px' }}>🏷️</span>
        20% OFF
      </div>
 */}
      <Typography variant="h4" margin={'0 0 24px 0'}>
        {title}
      </Typography>
      
      {/* Precio original tachado */}
      {originalPrice && (
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ textDecoration: 'line-through', marginRight: '10px', display: 'none' }}
        >
          €{originalPrice}
        </Typography>
      )}
      
      {/* Precio con descuento */}
      <Typography variant="h5" color="primary">
        €{price}
      </Typography>
      
      <Typography variant="h6">{year}</Typography>
      {topChildren}
      <Typography variant="body2" fontWeight={900} margin={'30px 0 10px 0'}>
        {description}
      </Typography>
      <Typography variant="body2">{performanceDrawing}</Typography>
      {bottomChildren}
      <div className="flex-center-row">
        {downloadUrl ? (
          <a href={downloadUrl} download style={{ width: '100% '}}>
            <Button
              variant="contained"
              size="medium"
              fullWidth
              disableElevation
              className="color btn-generic"
            >
              {button}
            </Button>
          </a>
        ) : (
          <Button
            variant="contained"
            size="medium"
            fullWidth
            disableElevation
            className="color btn-generic"
          >
            {button}
          </Button>
        )}
      </div>
    </article>
  );
};

export default Card;
