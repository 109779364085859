import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Asumiendo que usas react-i18next para las traducciones

export const PriceOptions = ({ onPlanChange }) => {
  const [tabValue, setTabValue] = useState('1year');
  const { t } = useTranslation(); // Obtener la función para traducir

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    onPlanChange(newValue);
  };

  return (
    <div className="price-options-container">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        sx={{
          backgroundColor: '#ffffff', 
          padding: '30px 0',
          '.MuiTabs-flexContainer': {
            justifyContent: 'center',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          },
          '.MuiTabs-indicator': {
            display: 'none',
          },
          '@media (max-width: 600px)': {
            padding: '15px 0',
          },
        }}
      >
        <Tab
          label={t('subscription.one_year')} // Usamos la traducción para "1 año"
          value="1year"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '120px',
            margin: '0 3px',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            },
            '@media (max-width: 600px)': {
              minWidth: '100px',
              maxWidth: '100%',
              width: '100%',
              margin: '5px 0',
            }
          }}
        />
        <Tab
          label={t('subscription.three_years')} // Usamos la traducción para "3 años"
          value="3years"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '140px',
            margin: '0 5px',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            },
            '@media (max-width: 600px)': {
              minWidth: '100px',
              maxWidth: '100%',
              margin: '5px 0',
            }
          }}
        />
        <Tab
          label={t('subscription.lifetime')} // Usamos la traducción para "De por vida"
          value="lifetime"
          sx={{
            backgroundColor: '#f2f2f2',
            border: '1px solid silver',
            boxSizing: 'border-box',
            minWidth: '140px',
            margin: '0 5px',
            '&.Mui-selected': {
              backgroundColor: '#3DB5B9',
              color: 'white',
              borderBottom: '1px solid #3DB5B9',
            },
            '@media (max-width: 600px)': {
              minWidth: '100px',
              maxWidth: '100%',
              margin: '5px 0',
            }
          }}
        />
      </Tabs>
    </div>
  );
};

export default PriceOptions;
