import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Snackbar,
  Typography,
  Alert,
  Tabs,
  Tab,
/*   Select,
  MenuItem, */
  useTheme, 
  useMediaQuery
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CardFull from './components/CardFull.tsx';
import PrivacyModal from './components/PrivacyModal.js';
import Card from './components/Card.tsx';
import { PriceOptions } from './components/PriceOptions.js';
import logo from './assets/logo-origiinal.svg';
import logoBricsys from './assets/logo-mini.png';
import logoLinkedin from './assets/linkedIn.svg';
import i18n from './i18n.js';
import CheckIcon from '@mui/icons-material/Check';
import { ParallaxProvider } from 'react-scroll-parallax';
import { motion } from 'framer-motion';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

export const Landing = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [tabValue, setTabValue] = useState('individual');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [planDuration, setPlanDuration] = useState('1year'); // Para seleccionar duración de la licencia

  // Función para detectar el idioma según el país
  useEffect(() => {
    const detectLanguage = async () => {
      const controller = new AbortController();
      const timeout = setTimeout(() => controller.abort(), 5000); // Timeout de 5 segundos
  
      try {
        const response = await axios.get('https://ipapi.co/json/', { signal: controller.signal });
        console.log('API response:', response.data); // Verifica los datos devueltos por la API
        const countryCode = response.data.country_code;
        console.log('Detected country code:', countryCode);
        const language = getLanguageFromCountryCode(countryCode);
        i18n.changeLanguage(language);
      } catch (error) {
        console.error('Error detecting IP:', error.message || error);
        i18n.changeLanguage('en'); // Idioma predeterminado a inglés
      } finally {
        clearTimeout(timeout);
      }
    };
    detectLanguage();
  }, []);
  
  // Función para mapear el código de país a un idioma
  const getLanguageFromCountryCode = (countryCode) => {
    const languageMap = {
      AR: 'es', // Español (Argentina)
      US: 'en', // Inglés (Estados Unidos)
      BR: 'pt', // Portugués (Brasil)
      IT: 'it', // Italiano (Italia)
      PT: 'pt', // Portugués (Portugal)
      GB: 'en', // Inglés (Reino Unido)
      UK: 'en', // Inglés (Reino Unido, alternativo)
    };
    return languageMap[countryCode] || 'en'; // Idioma predeterminado a inglés
  };

  const API_URL = process.env.NODE_ENV === 'production'
  ? 'https://addicad.com/send-email'
  : 'http://localhost:5001/send-email'; // Para ambiente local

// Función para manejar el envío del formulario
const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, message }),
    });

    if (response.ok) {
      setOpen(true);  // Mostrar notificación de éxito
    } else {
      const errorData = await response.json();  // Obtener detalles del error
      throw new Error(errorData.message || 'Error al enviar el correo');
    }
  } catch (error) {
    console.error('Error al enviar el correo:', error.message);
    setError(true);  // Mostrar notificación de error
  }
};

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const baseCards = [
    {
      title: t('planes.0.lite.name'),
      year: t('planes.0.lite.per_year'),
      request_demo: t('planes.0.lite.request_demo'),
      description: t('planes.0.lite.key_features'),
      performanceDrawing: t('planes.0.lite.performance_drawing'),
      topChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.lite.excl_taxes')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.lite.incl_maintenance')}
          </li>
        </ul>
      ),
      bottomChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.lite.drawing_tools')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.lite.lisp_routines')}
          </li>
        </ul>
      ),
    },
    {
      title: t('planes.0.pro.name'),
      year: t('planes.0.pro.per_year'),
      description: t('planes.0.pro.key_features'),
      performanceDrawing: t('planes.0.lite.performance_drawing'),
      request_demo: t('planes.0.pro.request_demo'),
      topChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.pro.excl_taxes')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.pro.incl_maintenance')}
          </li>
        </ul>
      ),
      bottomChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.pro.drawing_tools')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.pro.lisp_routines')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.pro.third_party_applications')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.pro.civil_and_topographic_toolsets')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.pro.point_cloud_toolsets')}
          </li>
        </ul>
      ),
    },
    {
      title: t('planes.0.mechanical.name'),
      year: t('planes.0.mechanical.per_year'),
      description: t('planes.0.mechanical.key_features'),
      performanceDrawing: t('planes.0.lite.performance_drawing'),
      request_demo: t('planes.0.mechanical.request_demo'),
      topChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.excl_taxes')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.incl_maintenance')}
          </li>
        </ul>
      ),
      bottomChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.drag_and_drop_presentation_design')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.mechanical_symbols_and_annotations')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.bill_of_materials_generation')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.sheet_metal_fabrication')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.piping_system_design')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.inspection_toolkit_and_assembly_instructions')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.mechanical.parametric_assembly_modeling')}
          </li>
        </ul>
      ),
    },
    {
      title: t('planes.0.bim.name'),
      year: t('planes.0.bim.per_year'),
      description: t('planes.0.bim.key_features'),
      performanceDrawing: t('planes.0.lite.performance_drawing'),
      request_demo: t('planes.0.bim.request_demo'),
      topChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.bim.excl_taxes')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.incl_maintenance')}
          </li>
        </ul>
      ),
      bottomChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.bim.ifc_classification_and_properties')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.ifc_import_and_export')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.rvt_and_rfa_import')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.civil_and_topographic_toolsets')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.tables_and_complete_bom_manager')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bim.bim_auto_classification')}
          </li>
        </ul>
      ),
    },
    {
      title: t('planes.0.bricsys_ultimate.name'),
      year: t('planes.0.bricsys_ultimate.per_year'),
      description: t('planes.0.bricsys_ultimate.key_features'),
      performanceDrawing: t('planes.0.lite.performance_drawing'),
      request_demo: t('planes.0.bricsys_ultimate.request_demo'),
      topChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.bricsys_ultimate.excl_taxes')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bricsys_ultimate.incl_maintenance')}
          </li>
        </ul>
      ),
      bottomChildren: (
        <ul>
          <li>
            <CheckIcon /> {t('planes.0.bricsys_ultimate.drawing_tool')}
          </li>
          <li>
            <CheckIcon /> {t('planes.0.bricsys_ultimate.lisp_routines')}
          </li>
        </ul>
      ),
    },
  ];

  // Actualizamos el valor de `tabValue` según el tipo de licencia seleccionada
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Manejamos el cambio de duración (1 año, 3 años, de por vida)
  const handlePlanChange = (newPlan) => {
    setPlanDuration(newPlan); // Actualizamos la duración seleccionada cuando cambie en `PriceOptions`
  };

  // Constante de porcentaje de incremento o descuento
 /*  const DISCOUNT_PERCENTAGE = 0.20; */
  const DISCOUNT_PERCENTAGE = 0; // Puedes cambiar esto por otro porcentaje en el futuro

  // Función para aplicar el porcentaje al precio
  const applyPercentage = (price) => {
    return price * (1 - DISCOUNT_PERCENTAGE);  // Aumenta el precio en un 20%
  };

  const individualPrices = [
    [251, 541, 760, 808, 896],  // Precios por 1 año para planes individuales
    [680, 1456, 2056, 2180, 2416],  // Precios por 3 años para planes individuales
    [565, 1214, 1708, 1814, 2016], // Precios de por vida para planes individuales
  ];

  const groupPrices = [
    [502, 1080, 1520, 1616, 1792],  // Precios por 1 año para licencias de red
    [1360, 2912, 4112, 4360, 4832],  // Precios por 3 años para licencias de red
    [1132, 2428, 3416, 3628, 4032], // Precios de por vida para licencias de red
  ];

  // Lógica para obtener los precios según el tipo de licencia y la duración seleccionada
  const getPrice = (index) => {
    const basePrice = tabValue === 'individual'
      ? individualPrices[planDuration === '1year' ? 0 : planDuration === '3years' ? 1 : 2][index]
      : groupPrices[planDuration === '1year' ? 0 : planDuration === '3years' ? 1 : 2][index];

    // Aplica el porcentaje al precio y lo formatea con comas
    const finalPrice = applyPercentage(basePrice);
    return finalPrice.toLocaleString();  // Devuelve el precio con formato
  };

  // Función para obtener el precio original (sin descuento)
  const getOriginalPrice = (index) => {
    const basePrice = tabValue === 'individual'
      ? individualPrices[planDuration === '1year' ? 0 : planDuration === '3years' ? 1 : 2][index]
      : groupPrices[planDuration === '1year' ? 0 : planDuration === '3years' ? 1 : 2][index];
  
    console.log("Original price for index", index, ":", basePrice); // Este log debería mostrarse
    return basePrice.toLocaleString(); // Devuelve el precio formateado
  };
  
  

  // Obtener el texto de año desde las traducciones
  const getYearText = () => {
    if (planDuration === '1year') return t('duration.one_year');
    if (planDuration === '3years') return t('duration.three_years');
    return t('duration.lifetime');
  };

  var sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ParallaxProvider>
      <div className="container-global">
        <section className="section-full background-img inicial flex-center-row padding-mobile">
         
          <header className='center flex-center-column content-center-home'>
             {/* Agregar select dropdown para cambiar de idioma */}
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
              {/* <Typography variant="h6">Selecciona un idioma:</Typography> */}
              {/* <Select
                value={language} // Estado del idioma
                variant="filled"
                onChange={changeLanguage} // Cambiar idioma
                style={{ width: '150px', marginBottom: 20 }}
              >
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="en">Inglés</MenuItem>
                <MenuItem value="pt">Portugués</MenuItem>
                <MenuItem value="it">Italiano</MenuItem>
              </Select> */}
            </div>
            <motion.img 
              src={logo} 
              alt="AddiCad - Software CAD" 
              height={45} 
              className="logo"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            />
             {/* <Typography variant="caption" margin="20px 0">
              {t('beta_version')}
            </Typography>*/}
            <Typography
              variant="h1"
              sx={{
                fontSize: isSmallScreen ? '2rem' : '2rem',
                lineHeight: isSmallScreen ? '50px' : '34px',
                [theme.breakpoints.up('md')]: {
                  fontSize: '2.8rem',
                  lineHeight: '65px',
                },
                [theme.breakpoints.up('lg')]: {
                  fontSize: '3rem',
                  lineHeight: '75px',
                },
              }}
              fontWeight={900}
              textAlign={'center'}
              lineHeight="85px"
            >
              {t('software_cad_professional')} <br />
              <b className="color-text">{t('no_commitment')}</b>
            </Typography>
            <Typography
              variant="body2"
              fontSize={22}
              textAlign="center"
              margin="30px 0"
            >
              {t('accelerate_delivery')}
            </Typography>
            <div className="flex-center-row">
              <a
                href="https://www.bricsys.com/bricscad-download?ref=1274&refam=3023"
                className="color btn-generic ma-l20 btn-animated"
              >
              <Button
                variant="contained"
                size="large"
                disableElevation
                className="color btn-generic ma-l20 btn-animated"
              >
                {t('request_demo_generic')}
              </Button>
              </a>
            </div>
          </header>
        </section>
        
        {/* Sección de las tarjetas de precios */}
        <motion.section className="section planes pad-section-34 padding-mobile" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} transition={{ duration: 0.5 }}>
          <div className="center flex-center-column content-center-home">
            <Typography variant="h5" fontSize={45} fontWeight={900} textAlign={'center'} lineHeight="65px">
              {t('choose_edition')} <br />
              <b className="color-text">{t('for_workflows')}</b>
            </Typography>
            <Typography variant="body2" fontSize={22} textAlign="center" margin="30px 0">
              {t('bricscad_platform')}
            </Typography>
          </div>
          <div className="tabs-container center content-center-home">
            <PriceOptions onPlanChange={handlePlanChange} />
          </div>
          <div className="tabs-container center content-center-home type">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              centered
              sx={{
                '.MuiTabs-flexContainer': {
                  justifyContent: 'center',
                  '@media (max-width: 480px)': {
                    flexDirection: 'column',
                    width: '100%'
                  }
                },
                '.MuiTabs-indicator': {
                  display: 'none',
                }
              }}
            >
              <Tab
                value="individual"
                label={t('license.individual')} // Traducción de "Licencia(s) individual(es)"
                sx={{
                  backgroundColor: '#f2f2f2',
                  border: '1px solid silver',
                  boxSizing: 'border-box',
                  minWidth: '140px',
                  margin: '0 5px',
                  borderRadius: '25px 0 0 25px',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    minWidth: '100%',
                    borderRadius: '25px',
                    marginBottom: '15px'
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#000000',
                    color: 'white',
                    borderBottom: '1px solid #000000'
                  },
                }}
              />
              <Tab
                value="group"
                label={t('license.network')} // Traducción de "Licencia de red"
                sx={{
                  backgroundColor: '#f2f2f2',
                  border: '1px solid silver',
                  boxSizing: 'border-box',
                  minWidth: '140px',
                  margin: '0 5px',
                  borderRadius: '0 25px 25px 0',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    minWidth: '100%',
                    borderRadius: '25px',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#FFFFFF',
                    color: 'black',
                    borderBottom: '1px solid silver'
                  },
                }}
              />
            </Tabs>
          </div>
          <div className="center content-center-home pad-section-34">
            <CardFull
              title={t('planes.0.bricsys_ultimate.name')}
              originalPrice={getOriginalPrice(4)}
              price={getPrice(4)}  // Aplica el precio dinámico basado en tabValue y planDuration
              year={getYearText()}  // Cambia el texto del año dinámicamente
              description={t('planes.0.bricsys_ultimate.performance_drawing')}
              button={t('planes.0.bricsys_ultimate.request_demo')}
              buttonDemo={t('planes.0.bricsys_ultimate.buy_now')}
              downloadUrl={'https://www.bricsys.com/bricscad-download?ref=1274&refam=3023'}
              children={
                <ul>
                  <li>{t('planes.0.bricsys_ultimate.incl_maintenance')}</li>
                  <li>{t('planes.0.bricsys_ultimate.key_features')}</li>
                </ul>
              }
            />
          </div>
          <div className="slider-container center content-center-home">
            <Slider {...sliderSettings}>
              {baseCards.map((card, index) => (
                <motion.div key={index} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                  <Card
                    key={index}
                    title={card.title}
                    price={getPrice(index)}  // Precio dinámico según la selección de individual o grupo y años
                    originalPrice={getOriginalPrice(index)}
                    year={getYearText()}  // Cambia el texto del año dinámicamente
                    button={card.request_demo}
                    description={card.description}
                    topChildren={card.topChildren}
                    bottomChildren={card.bottomChildren}
                    downloadUrl={'https://www.bricsys.com/bricscad-download?ref=1274&refam=3023'}
                  />
                </motion.div>
              ))}
            </Slider>
          </div>
        </motion.section>

        {/* Sección de contacto */}
        <motion.section className="section contacto padding-mobile bg-gray" initial={{ opacity: 0, y: 50 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} transition={{ duration: 0.5 }}>
          <div className="center flex-center-row-mobile">
            <div className="left mobile background-img-contacto"></div>
            <div className="right flex-left-column">
              <Typography variant="h5" fontSize={47} sx={{ fontSize: isSmallScreen ? '2rem' : '2rem', [theme.breakpoints.up('md')]: { fontSize: '2.5rem', }, [theme.breakpoints.up('lg')]: { fontSize: '3rem', }, }} fontWeight={900} margin="10px 0">
                <b className="color-text">{t('contact_us')}</b> <br /> {t('sales_department')}
              </Typography>
              <form onSubmit={handleSubmit} style={{ maxWidth: 450 }}>
                <TextField label={t('name')} variant="outlined" fullWidth margin="normal" value={name} style={{backgroundColor: 'white'}} onChange={(e) => setName(e.target.value)} required aria-required="true" />
                <TextField id="email" label={t('email')} variant="outlined" fullWidth margin="normal" type="email" value={email} style={{backgroundColor: 'white'}} onChange={(e) => setEmail(e.target.value)} required aria-required="true" autoComplete="email" />
                <TextField id="phone" label={t('phone')} variant="outlined" fullWidth margin="normal" type="phone" value={email} style={{backgroundColor: 'white'}} onChange={(e) => setEmail(e.target.value)} required aria-required="true" autoComplete="phone" />
                <TextField label={t('message')} variant="outlined" fullWidth margin="normal" multiline rows={4} value={message} style={{backgroundColor: 'white'}} onChange={(e) => setMessage(e.target.value)} required aria-required="true" />
                <Button type="submit" variant="contained" disableElevation color="primary" className="color btn-generic" sx={{ mt: 2 }}>
                  {t('send')}
                </Button>
              </form>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>{t('query_sent_successfully')}</Alert>
              </Snackbar>
              <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>{t('query_send_error')}</Alert>
              </Snackbar>
            </div>
          </div>
        </motion.section>

        {/* Footer */}
        <footer>
          <div className="center footer-flex">
            <div className='left-footer'>
              <img src={logoBricsys} width={120} alt="Bricsys by Addicad" />
              <p>© 2024, Addicad.</p>
            </div>
            <div className='right-footer'>
              <ul className='items'>
                <li><PrivacyModal /></li>
              </ul>
              <ul className='social-media'>
                <li style={{display: 'flex', alignItems: 'center'}}>
                <AddIcCallIcon /><Typography fontSize={12}> <b>+34919931208</b></Typography>
                </li>
                <li>
                  <a href="https://www.linkedin.com/" aria-label="LinkedIn de Addicad">
                    <img src={logoLinkedin} alt="LinkedIn de Addicad" />
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </footer>
      </div>
    </ParallaxProvider>
  );
}
